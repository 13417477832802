import React from 'react';
import '../styles/layouts/Header.scss';

export const Footer = () => {
  return (
    <div className="footer">
      © Beaverbrook Centre
    </div>
  );
};
