import React, { useState, useEffect } from 'react';
import '../styles/layouts/Header.scss';

const MENUS = [
  {
    name: 'Introduction',
    id: '#introduction',
  },
  // {
  //   name: 'Store Directory',
  //   id: '#store-directory',
  // },
  {
    name: 'Our Location',
    id: '#our-location',
  },
  {
    name: 'Contact Us',
    id: '#contact-us',
  },
];

export const Header = () => {
  const [isShowMenuMobile, setIsShowMenuMobile] = useState<boolean>(false);

  useEffect(() => {
    if (isShowMenuMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowMenuMobile]);

  const handleScrollClick = (id: string) => {
    if (!id) return;
    const divRef: any = document.getElementById(id);
    const divOffset = divRef?.offsetTop;
    let offsetSmooth = divOffset - 100;

    if (window.innerWidth < 768) {
      offsetSmooth = divOffset - 60;
    }

    window.scrollTo({
      top: offsetSmooth,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`${isShowMenuMobile ? 'mobile' : ''} header`} id='header'>
      {!isShowMenuMobile && (
        <div className="header__content">
          <div className="header__logo">
            <img src={'/images/logo.svg'} alt="logo" />
          </div>

          <div className="header__menu" onClick={() => setIsShowMenuMobile(true)}>
            <div className="icon-menu"></div>
          </div>

          <div className="list-menu">
            {MENUS.map((menu, index) => {
              return (
                <div
                  className="list-menu__item"
                  key={index}
                  onClick={() => handleScrollClick(menu.id.replace('#', ''))}
                >
                  {menu.name}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isShowMenuMobile && (
        <div className="header__mobile">
          <div className="header__icon-close">
            <div className="icon-close" onClick={() => setIsShowMenuMobile(false)}></div>
          </div>

          <div className="header__mobile-menu">
            {MENUS.map((menu, index) => {
              return (
                <div
                  className="header__mobile-menu--item"
                  key={index}
                  onClick={() => {
                    handleScrollClick(menu.id.replace('#', ''));
                    setIsShowMenuMobile(false);
                  }}
                >
                  {menu.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
