import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper';
import Modal from 'react-modal';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const HOLIDAYS = [
  'New Years',
  'Family Day',
  'Good Friday',
  'Victoria Day',
  ' Canada Day',
  'Civic Holiday',
  'Labour Day',
  'Thanksgiving',
  'Christmas',
  'Boxing Day',
];

const PartOurLocation = () => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  // const dataUtilities = config.data.utilities;

  useEffect(() => {
    if (isShowModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isShowModal]);

  return (
    <>
      <div className="our-location">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
            <div className="centre-hours">
              <h2 className="centre-hours__title">Centre Hours</h2>

              <div className="centre-hours__desc">
                Store hours may differ, please check with your business.
              </div>

              <div className="centre-hours__info">
                <div className="centre-hours__date">Monday - Friday</div>
                <div className="centre-hours__time">8:00am – 6:00pm</div>
              </div>

              <div className="centre-hours__info">
                <div className="centre-hours__date">Saturday</div>
                <div className="centre-hours__time">8:00am – 4:00pm</div>
              </div>

              <div className="centre-hours__info">
                <div className="centre-hours__date">Sunday</div>
                <div className="centre-hours__time">Closed</div>
              </div>

              <div className="centre-hours__close">
                Closed on{' '}
                <span className="centre-hours__holidays" onClick={() => setIsShowModal(true)}>
                  statutory holidays
                </span>
              </div>
            </div>

            <Modal
              isOpen={isShowModal}
              ariaHideApp={false}
              onRequestClose={() => setIsShowModal(false)}
              style={{
                overlay: {
                  background: 'rgba(0, 0, 0, 0.80)',
                  zIndex: 9999,
                },
                content: {
                  width: '270px',
                  height: '392px',
                  top: '40%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  padding: '20px 20px 16px 20px',
                  transform: 'translate(-50%, -50%)',
                },
              }}
            >
              <div className="modal__content">
                <div className="modal__icon-close">
                  <div className="icon-close-modal" onClick={() => setIsShowModal(false)}></div>
                </div>

                <div className="modal__title">Holiday Hours</div>

                <div className="modal__list-holiday">
                  {HOLIDAYS.map((item, index) => {
                    return (
                      <div className="holiday" key={index}>
                        <div className="holiday__name">{item}</div>
                        <div className="holiday__close">- Closed</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-9" id="our-location">
            <div className="box-our-location">
              <h2 className="box-our-location__title">Our Location</h2>

              <div className="box-our-location__map">
                <div className="box-our-location__box-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2804.98611000268!2d-75.90413212325419!3d45.32890294245502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cd2000121150d5d%3A0xdbda530be2747b5a!2sBeaverbrook%20Centre!5e0!3m2!1sen!2s!4v1692753910439!5m2!1sen!2s"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Beaverbrook Centre"
                  />
                </div>
                <div className="box-our-location__address">
                  <div className="box-our-location__name">Beaverbrook Centre</div>
                  <div className="box-our-location__address-detail">
                    1002 Beaverbrook Rd Ottawa, ON K2K 1L1 Canada
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="list-utilities">*/}
      {/*  <div className="list-utilities__content">*/}
      {/*    <Swiper*/}
      {/*      spaceBetween={10}*/}
      {/*      navigation={false}*/}
      {/*      pagination={true}*/}
      {/*      breakpoints={{*/}
      {/*        768: {*/}
      {/*          slidesPerView: 1,*/}
      {/*          spaceBetween: 0,*/}
      {/*        },*/}
      {/*        992: {*/}
      {/*          slidesPerView: 2,*/}
      {/*          spaceBetween: 10,*/}
      {/*        },*/}
      {/*        1024: {*/}
      {/*          slidesPerView: 4,*/}
      {/*          spaceBetween: 10,*/}
      {/*        },*/}
      {/*      }}*/}
      {/*      modules={[Pagination, Navigation]}*/}
      {/*    >*/}
      {/*      <div className="row">*/}
      {/*        {dataUtilities.map((utility, index) => {*/}
      {/*          return (*/}
      {/*            <SwiperSlide key={index} className="utility">*/}
      {/*              <div>*/}
      {/*                <div className="utility__content">*/}
      {/*                  <div className="utility__image">*/}
      {/*                    <img src={utility.image} alt={utility.title} />*/}
      {/*                  </div>*/}
      {/*                  <div className="utility__title">{utility.title}</div>*/}
      {/*                  <div*/}
      {/*                    className="utility__description"*/}
      {/*                    dangerouslySetInnerHTML={{ __html: utility.description }}*/}
      {/*                  />*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </SwiperSlide>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </div>*/}
      {/*    </Swiper>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default PartOurLocation;
