import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import config from '../../config';
import { toast } from 'react-toastify';

const options = [
  {
    label: 'General Inquiry',
    value: 'General Inquiry',
  },
  {
    label: 'Lease & Building Management Inquiry',
    value: 'Lease & Building Management Inquiry',
  },
  {
    label: 'Feedback',
    value: 'Feedback',
  },
  {
    label: 'Kanata Beaverbrook Community Centre Bookings, more info: <a href=\'https://www.kanatabeaverbrook.ca/joomla16/index.php/community-centre\' target=\'_blank\'>KBCA Bookings</a>',
    value: 'KBCA Bookings',
  },
];

const PartContactUs = () => {
  const [type, setType] = useState<string>('General Inquiry');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      name: '',
      number: '',
      message: '',
    },
  });

  const onSubmit = async (data: any) => {
    const json = JSON.stringify({ ...data, type, access_key: config.auth.access_key });

    await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          toast.success('Submit Successfully!');
          reset();
        } else {
          toast.error(json?.message || 'Something Went Wrong!');
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Something Went Wrong!');
      });
  };

  return (
    <div className="contact-us" id="contact-us">
      <h2 className="contact-us__title">Contact Us</h2>
      <div className="contact-us__description">
        For general, lease, building management inquiries and feedback please fill out the contact
        form.
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form">
        {/*<span className="contact-us__note">*/}
        {/*  For Kanata Beaverbrook Community Centre Bookings please visit: &nbsp;*/}
        {/*  <a*/}
        {/*    href="https://www.kanatabeaverbrook.ca/joomla16/index.php/community-centre "*/}
        {/*    target="_blank"*/}
        {/*  >*/}
        {/*    KBCA Bookings*/}
        {/*  </a>*/}
        {/*</span>*/}
        <div className="row">
          <div className="col-12 col-md-7 form__left">
            <div className="form__type">
              {options.map((option, index) => {
                return (
                  <div
                    className="form__type-item"
                    key={index}
                    onClick={() => setType(option.value)}
                  >
                    <div className={`icon ${type === option.value ? 'selected' : ''} `} />
                    <div dangerouslySetInnerHTML={{ __html: option.label }}></div>
                  </div>
                );
              })}
            </div>

            <div className={'form__field'}>
              <div className={'form__label'}>Name</div>
              <input type="text" {...register('name', { required: true })} />
              {errors.name && <div className="error">Name is required.</div>}
            </div>

            <div className={'form__field'}>
              <div className={'form__label'}>Number</div>
              <input
                type="number"
                {...register('number', {
                  required: 'Number is required.',
                })}
              />
              <div className="error">{errors.number?.message}</div>
            </div>

            <div className={'form__field'}>
              <div className={'form__label'}>Email</div>
              <input
                type="email"
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              />
              <div className="error">{errors.email?.message}</div>
            </div>

            <button type="submit" className="form__btn-submit desktop">
              Submit
            </button>
          </div>

          <div className="col-12 col-md-5 form__right">
            <div className={'form__field'}>
              <div className={'form__label'}>Message</div>
              <textarea {...register('message', { required: true })} />
              {errors.message && <div className="error">Message is required.</div>}
            </div>

            <button type="submit" className="form__btn-submit mobile">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PartContactUs;
