import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const PartBanner = () => {
  const [isPlay, setPlay] = useState<boolean>(false);

  useEffect(() => {
    const header = document.getElementById('header') as any;
    if (isPlay) {
      document.body.style.overflow = 'hidden';
      header.style.zIndex = 1;
    } else {
      document.body.style.overflow = 'inherit';
      header.style.zIndex = 1000;
    }
  }, [isPlay]);

  return (
    <>
      <div className="section-contact">
        <div>
          Commercial space for lease, please contact (613) 798-3842.
        </div>
      </div>
      <div className="banner" id="introduction">
      <div className="banner__video">
        <img src={'/images/banner.jpeg'} alt="banner" />
        <div className="banner__layout">
          <div className="banner__icon-play" onClick={() => setPlay(true)}>
            <div className="icon-play"></div>
          </div>
        </div>
      </div>

      <div className="banner__detail">
        <div className="banner__detail-content">
          <div className="row">
            <div className="col-12 col-lg-4 banner__title">
              Come discover <br />
              <span>Beaverbrook Centre</span>
            </div>

            <div className="col-12 col-lg-8 banner__description">
              Welcome to the Beaverbrook Centre, a vibrant hub nestled in the heart of Beaverbrook,
              offering convenient access to the bustling Kanata North Business Park and the serene
              Kanata Lakes. Located just minutes away from the Queensway (Highway 417), our prime
              two-story brick building encompasses 47,000 square feet of commercial real estate
              space. At Beaverbrook Centre, we're more than just bricks and mortar – we're a dynamic
              community hub boasting amenities like a pool and tennis court. Our mixed-use
              commercial space accommodates a diverse array of businesses, including those in
              healthcare, education, and professional services. Join us and experience the perfect
              blend of convenience, functionality, and opportunity.
            </div>
          </div>
        </div>
      </div>

      {isPlay && (
        <div className="banner__box-video" id="video">
          <div
            className="banner__toolbar"
            onClick={() => {
              setPlay(false);
            }}
          >
            <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
            </svg>
          </div>
          <ReactPlayer
            controls
            onEnded={() => setPlay(false)}
            width="960px"
            height="540px"
            url="https://www.youtube.com/watch?v=ly5LZi2ja04"
          />
        </div>
      )}
    </div>
    </>
  );
};

export default PartBanner;
