import React from 'react';
import { BasePage } from '../../layouts';
import '../../styles/pages/HomePage.scss';
import PartBanner from './PartBanner';
//import PartStoreDirectory from './PartStoreDirectory';
import PartOurLocation from './PartOurLocation';
import PartContactUs from './PartContactUs';

export const HomePage = () => {
  return (
    <BasePage>
      <div>
        <PartBanner />
        <div className="content">
          {/*<PartStoreDirectory />*/}
          <PartOurLocation />
          <PartContactUs />
        </div>
      </div>
    </BasePage>
  );
};
