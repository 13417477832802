import prod from './prod.json';
import dev from './dev.json';

const env = process.env.REACT_APP_ENV || 'prod';
const configs: any = {
  dev,
  prod
};

const config: Config = configs[env];

export interface Config {
  auth: {
    access_key: string;
  };
  data: {
    store: IStore[];
    utilities: IUtility[];
  };
}

export default config;

export interface IStore {
  name: string;
  image: string;
  link: string;
  type: string;
}

export interface IUtility {
  title: string;
  description: string;
  image: string;
}
